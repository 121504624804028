@import 'variables.scss';

.AutomationsPage__Body {
    padding-inline: var(--layoutPadding);
}

.AutomationsPage__Error {
    max-width: calc(100vw - var(--layoutPadding));
}

.AutomationsPage__Empty {
    color: $color-grey-base;
}

.AutomationsPage__AutomationsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.AutomationsPage__AutomationSetDisplay {
    background-color: white;
    padding: 12px 24px;
    border-radius: 5px;
}

.AutomationsPage__AutomationSetDisplay__Header {
    margin-bottom: 24px;
    background-color: white;
    padding-block: 12px;
    border-radius: 5px;
    position: sticky;
    z-index: 1;

    @media #{$small-down} {
        top: $topBarHeight;
    }
    @media #{$medium-up} {
        top: 0;
    }

    .PropertyDisplay__Value__Text {
        font-family: monospace;
        font-size: 12px;
        color: $color-grey-dark;
    }
}

.AutomationsPage__AutomationRow {
    margin-bottom: 24px;
    padding-top: 24px;
    border-top: 1px solid $color-grey-lighter;
}

.AutomationsPage__AutomationRow__Type {
    font-size: 12px;
    display: inline-block;
    color: $color-grey-dark;
}

.AutomationsPage__AutomationRow__TypeWarning {
    color: $color-warning-dark;
    font-size: 22px;
    vertical-align: middle;
    line-height: 22px;
    margin-left: 4px;
}

.AutomationsPage__ConstraintDisplay,
.AutomationsPage__ActionDisplay {
    display: flex;
    gap: 0.25em;
    flex-wrap: wrap;
    margin-bottom: 4px;
    align-items: center;
}

.AutomationsPage__JsonContent {
    background-color: $color-grey-lighter;
    border: 1px solid $color-grey-light;
    border-radius: 5px;
    padding: 12px;
    font-size: 12px;
}
