@import '../../variables.scss';

.body--LoginPage {
    background-color: $color-primary-base;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.LoginPage {
    width: 512px;
    background-color: white;
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.LoginPage--LoggingOut {
    background-color: transparent;
    width: auto;
    gap: 24px;
    color: $color-secondary-light;
    animation: LoggingOutFadeIn 1.5s linear;
}

@keyframes LoggingOutFadeIn {
    0% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.LoginPage__Logo {
    width: 190px;
    height: 199px;
    margin-bottom: 16px;
}

.LoginPage__ForgotWrapper {
    text-align: center;
}
