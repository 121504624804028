@import '../../../../variables.scss';

.AccountingConnectionStatusBadge {
    &.PENDING {
        color: $color-warning-darker;
        background-color: $color-warning-base;
        border-color: $color-warning-base;
    }
    &.FAILED {
        color: $color-danger-base;
        background-color: $color-danger-lightest;
        border-color: $color-danger-base;
    }
    &.FULFILLED {
        background-color: $color-checked-green;
        border-color: $color-checked-green;
    }
    &.EXTERNAL {
        color: $color-primary-dark;
        background-color: white;
        border-color: $color-primary-base;
    }
}
