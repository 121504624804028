@import '../../../../variables.scss';

.SalesOrderEditModal__Frame {
    width: 600px;
}

.SalesOrderEditModal__Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.SalesOrderEditModal__TotalFreight--overridden {
    color: $color-grey-light;
    text-decoration: line-through;
}

.SalesOrderEditModal__FreightOverride {
    display: flex;
    align-items: center;
    gap: 8px;
}
