@import '../../../../variables.scss';

.InventoryReceiptDetailModal__Frame {
    width: 800px;
}

.InventoryReceiptDetailModal__Toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 8px;
}

.InventoryReceiptDetailModal__Toolbar__Info {
    color: $color-danger-base;
    font-weight: bold;
}

.InventoryReceiptDetailModal__MessagePanel {
    margin-bottom: 16px;
}

.InventoryReceiptDetailModal__Table {
    .DataTable__DataRow {
        position: relative;
    }
    .DataTable__SelectButton {
        top: 12px;
        height: 24px;
    }
    .DataTable__DataCell {
        vertical-align: top;
    }
    .DataTable__DataCell--description {
        overflow: visible;
    }
    .DataTable__DataCell--quantity {
        padding: 14px 24px;
        font-weight: $font-weight-bold;
    }
}

.InventoryReceiptDetailModal__Table__Description {
    padding: 4px 0;
    font-weight: $font-weight-bold;
}

.InventoryReceiptDetailModal__Table__Error {
    margin-block: 2px;
    color: $color-danger-base;
}
.InventoryReceiptDetailModal__Table__Error__Icon {
    margin-bottom: -2px;
    margin-right: 2px;
}

.InventoryReceiptDetailModal__Table__LocationWrapper {
    margin-right: -124px;
}

.InventoryReceiptDetailModal__Table__Location {
    display: flex;
    justify-content: space-between;
    color: $color-secondary-base;
    &:nth-child(2n) {
        color: $color-secondary-dark;
    }
}

.InventoryReceiptDetailModal__Table__LocationPath {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px 2px;
}

.InventoryReceiptDetailModal__Table__LocationPath__Icon {
    color: $color-grey-base;
    margin-bottom: -0.15em;
}

.InventoryReceiptDetailModal__Table__Quantity {
    min-width: 52px;
    text-align: right;
}
