@import '../../../../variables.scss';

.CustomerDetailModal__Frame {
    width: 700px;
}

.CustomerDetailModal__PageHeader__Actions {
    display: flex;
    gap: 6px;
}

.CustomerDetailModal__Body {
    .PropertyEdit__Label,
    .PropertyDisplay__Label {
        width: 200px;
        min-width: 200px;
    }
}

.CustomerDetailModal__TaxTab {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.CustomerDetailModal__TaxTab__WarningPanel {
    margin-bottom: 24px;
}

.CustomerDetailModal__TaxTab__Empty {
    color: $color-grey-base;
}

.CustomerDetailModal__TaxTab__Title {
    margin-top: 0;
    margin-bottom: 8px;
}

.CustomerDetailModal__TaxTab__ExemptionValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CustomerDetailModal__TaxTab__ExemptionValue__DeleteButton {
    padding: 0;
    min-width: 24px;
}

.CustomerDetailModal__TaxTab__AddExemption {
    align-self: flex-start;
    margin-top: 12px;
}
