@import '../../../../variables.scss';

.SalesOrderStatusBadge {
    &.UNSTARTED {
        background-color: $color-secondary-light;
        border-color: $color-secondary-light;
        color: $color-secondary-dark;
    }
    &.IN_PROGRESS {
        background-color: $color-primary-base;
        border-color: $color-primary-base;
        color: white;
    }
    &.ON_HOLD {
        background-color: $color-warning-base;
        border-color: $color-warning-base;
        color: $color-warning-darker;
    }
    &.CANCELLED {
        background-color: $color-danger-base;
        border-color: $color-danger-base;
        color: white;
    }
    &.COMPLETED {
        background-color: $color-accent-light;
        border-color: $color-accent-light;
        color: $color-accent-darker;
    }
}
