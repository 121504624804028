@import '../../../../variables.scss';

.SalesOrderHistoryTab__DataTable {
    .DataTable__DataCell:first-child {
        padding-left: 12px;
    }
    .DataTable__DataCell:last-child {
        padding-right: 12px;
    }
}

.SalesOrderHistoryTab__SectionEmpty {
    text-align: center;
    color: $color-grey-light;
    padding: 12px;
}

.SalesOrderHistoryTab__Loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
}
