@import '../../../../variables.scss';

.StocktakeReviewModal__Frame {
    width: 1400px;
}

.StocktakeReviewModal__Progress {
    padding-top: 8px;
    padding-right: 16px;

    .label {
        vertical-align: middle;
        margin-right: 8px;
    }
    .value {
        font-weight: $font-weight-bold;
        font-size: 18px;
        vertical-align: middle;
    }
}

.StocktakeReviewModal__InventoryTable {
    .DataTable__Col--expectedQuantity,
    .DataTable__Col--difference,
    .DataTable__Col--countedQuantity,
    .DataTable__Col--movementsOnHand,
    .DataTable__Col--adjustedDifference {
        white-space: nowrap;
        text-align: center;
    }
}

.StocktakeReviewModal__DiffDisplay {
    &.positive {
        color: $color-checked-green;
        font-weight: $font-weight-bold;
    }
    &.negative {
        color: $color-danger-base;
        font-weight: $font-weight-bold;
    }
}

.StocktakeReviewModal__InventoryTable {
    .DataTable__Col--edit {
        padding-right: 0;
    }
}

.StocktakeReviewModal__RowEditButton {
    padding: 0 8px;
    min-width: 0;
    color: $color-secondary-base;
}

.StocktakeReviewModal__EditCountDialog {
    width: 400px;
}

.StocktakeReviewModal__EditCountDialog__FieldsWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.StocktakeReviewModal__EditCountDialog__Footer {
    display: flex;
    gap: 6px;
    justify-content: flex-end;
    padding-top: 40px;

    .MyButton {
        min-width: 100px;
        flex: 1;
    }
}
