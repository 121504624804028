@import '../../../../variables.scss';

.ProductOptionDisplayGridLegacy__OptionsLoading {
    height: 58px;
}

.ProductOptionDisplayGridLegacy__SectionEmpty {
    color: $color-grey-light;
}

.ProductOptionDisplayGridLegacy__AdvancedOptionsLink {
    margin-top: 12px;
}

.ProductOptionDisplayGridLegacy__AdvancedOptionsModalLegacy__Frame {
    width: 700px;
}

.ProductOptionDisplayGridLegacy__AdvancedOptionsModalLegacy__Body {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.ProductOptionDisplayGridLegacy__AdvancedOptionsModalLegacy__SecondaryProduct__Title {
    margin-top: 0;
    margin-bottom: 16px;
}
