@import '../../../../variables.scss';

.InventoryReceiptInventoryInput {
    &.InventoryReceiptInventoryInput--multiple {
        width: 100%;
        .MuiInputBase-root {
            height: auto;
            width: 100%;
        }
    }

    .MuiChip-root {
        background-color: $color-secondary-base;
        color: white;
        font-size: 13px;
        gap: 6px;
        padding: 0 6px;
    }
    .MuiChip-deleteIcon {
        color: white;
        opacity: 0.3;
        font-size: 16px;
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon {
        .MuiInputBase-root {
            padding-right: 50px;
        }
    }
}

.InventoryReceiptInventoryInput__TextField {
    .MuiInputBase-input {
        min-width: 120px;
    }
}
