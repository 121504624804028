@import '../../../../variables.scss';

.WorkstationStepSidebar {
    max-width: 150px;
    border-left: 2px solid $color-secondary-lighter;
    padding-left: 24px;
}

.WorkstationStepSidebar__Links {
    margin: 0;
    padding-left: 0;
    list-style: none;

    li {
        &::before {
            content: '\23F5';
            margin-right: 8px;
        }
    }
}
