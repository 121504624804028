@import '../../variables.scss';

.FilterStrip {
    width: 100%;
}

.FilterStrip__Filters {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 8px 6px;
    flex-wrap: wrap;

    .FieldValidator__ErrorWrapper.hide {
        display: none;
    }
}

.FilterStrip__Filter--NonSticky {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 4px;
}
.MyButton.FilterStrip__Filter--NonSticky__CloseButton {
    width: 24px;
    min-width: 24px;
}

.FilterStrip__AddFilterButton {
    .MyButton {
        color: $color-grey-base;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 8px;
        min-width: 40px;
        width: 40px;

        &:hover {
            color: $color-grey-dark;
            border-color: $color-grey-dark;
        }
    }
}
