@import '../../../../variables.scss';

.ScheduleMain__PageHeader {
    margin-bottom: 12px;
}

.ScheduleMain__CalendarPickerWrapper {
    display: flex;
    height: 40px;

    .MyButton {
        max-height: 100%;
    }
}

.ScheduleMain__CalendarPicker {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.MuiButton-root.ScheduleMain__ThisWeekButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-left: 0;

    .MyButton__Inner__IconRight {
        width: 16px;
        height: 16px;
    }

    &.Mui-disabled {
        background-color: transparent;
        box-shadow: none;
        .MyButton__Inner__Label {
            color: $color-grey-base;
            font-weight: $font-weight-normal;
        }
        .MyButton__Inner__IconRight {
            color: $color-checked-green;
        }
    }
}

.ScheduleMain__DatesList {
    flex: 1;
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.ScheduleMain__DatesList__LoadingIndicator {
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .MyLinearProgress {
        width: 300px;
    }
}

.ScheduleMain__DatesList__Error {
    background-color: white;
    padding: 24px;
    border-radius: 5px;
}

.ScheduleMain__DateBlock {
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: flex-start;
    width: fit-content;
    position: relative;

    padding-bottom: 9px;
    border-bottom: 1px solid $color-grey-lighter;

    &--Loading {
        opacity: 0.5;
    }
    &--Collapsed {
        max-height: 56px;
    }
}

.ScheduleMain__DateBlock__Header {
    display: flex;
    align-items: center;
    padding: 16px;
    position: sticky;
    left: 0;
    gap: 12px;
}

.ScheduleMain__DateBlock__Header__Title {
    font-size: 16px;
    margin: 0;
    font-weight: $font-weight-normal;
    z-index: 1;
    flex: 0 1 auto;
    width: 160px;
}

.ScheduleMain__DateBlock__Header__Counts {
    font-size: 12px;
    display: flex;
    gap: 12px;
    align-items: center;
    white-space: nowrap;
    min-width: 48px;

    &.empty {
        color: $color-grey-base;
    }

    .icon {
        width: 18px;
        height: 18px;
        min-width: 18px;
    }
}

.ScheduleMain__DateBlock__Header__TodayBadge {
    font-weight: bold;
    background-color: $color-accent-base;
    color: white;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 5px;
    margin-left: 12px;
}

.ScheduleMain__DateBlock__Header__ExpandButton {
    min-width: 0;
    height: 24px;
    padding: 6px;
}

.ScheduleMain__DateBlock__CollapsedDropZone {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    .smooth-dnd-container {
        height: 100%;
    }

    &.isDragging {
        z-index: 1;
    }
}
