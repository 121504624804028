@import '../../../../variables.scss';

.SalesOrderAttachmentsTab {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.SalesOrderAttachmentsTab__Heading {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: -8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SalesOrderAttachmentsTab__TableContainer {
    background-color: $color-secondary-lighter;
    border-radius: 5px;

    .DataTable {
        background-color: transparent;
    }
}
