@import '../../../../variables.scss';

.WorkItemStepStatusBadge {
    &.READY {
        background-color: white;
        color: $color-primary-base;
        border-color: $color-primary-light;
    }
    &.COMINGUP {
        background-color: white;
        color: $color-secondary-base;
        border-color: $color-secondary-light;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
        border-color: $color-primary-light;
    }
    &.ONHOLD {
        background-color: $color-danger-light;
        border-color: $color-danger-light;
        color: white;
    }
    &.COMPLETED,
    &.COMPLETED_LOCKED {
        background-color: $color-accent-light;
        border-color: $color-accent-light;
        color: $color-accent-darker;
    }
    &.SKIPPED,
    &.SKIPPED_LOCKED {
        background-color: $color-accent-lighter;
        border-color: $color-accent-lighter;
        color: $color-accent-dark;
    }
}
