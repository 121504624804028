@import '../../../../variables.scss';

.WorkstationStepsTable {
    .DataTable__DataCell {
        vertical-align: top;
    }
}

.WorkstationStepsTable__StatusColumn {
    width: 150px;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 18px;
}

.WorkstationStepsTable__LabelValueColumn {
    width: 200px;
    max-width: 200px;
}

.WorkstationStepsTable__FilterColumn {
    width: 1px;
    position: sticky;
    right: 0;
    padding-right: 12px !important;
    padding-left: 6px;
    padding-top: 12px;
}

.WorkstationStepsTable__LabelValueCell {
    display: flex;
    flex-direction: column;
}

.WorkstationStepsTable__LabelValueCell__Label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;
    line-height: 19px;
    max-height: 19px; // just enough room for 1 line
    overflow: hidden;

    @media #{$medium-down} {
        word-break: break-all;
    }
}
.WorkstationStepsTable__LabelValueCell__Value {
    overflow: hidden;
    line-height: 19px;
    max-height: calc(19px * 3); // just enough room for 3 lines
    // text-overflow: ellipsis;

    .empty {
        color: rgba(0, 0, 0, 0.4);
    }

    @media #{$medium-down} {
        word-break: break-all;
    }
}

.WorkstationStepsTable__FilterMenuButton {
    .MyButton {
        padding: 0;
        min-width: 28px;

        .MyButton__Inner__IconRight {
            font-size: 16px;
        }
    }
}

.WorkstationStepsTable__FilterMenuItemLabel {
    display: flex;
    gap: 24px;
    align-items: center;
    line-height: 24px;
    justify-content: space-between;

    label {
        color: $color-grey-base;
        font-size: 12px;
    }
}

.WorkstationStepsTable__DataRow--collapsed {
    .DataTable__DataCell {
        // background-color: $color-secondary-light !important;
        background-color: $color-grey-lighter !important;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .DataTable__DataCell:not(:first-child) {
        // hide cell contents
        > * {
            display: none;
        }
    }
    .DataTable__DataCell:first-child {
        column-span: all;

        .WorkstationStepsTable__LabelValueCell__Value {
            display: none;
        }
    }

    & + .WorkstationStepsTable__DataRow--collapsed {
        .DataTable__DataCell {
            border-top: 0;
        }
    }
}
