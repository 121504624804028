@import '../../../../variables.scss';

.InventoryDetailsMeasures__Toolbar {
    margin-block: 8px 16px;
}

.InventoryDetailsMeasures__Table {
    .DataTable__Col--canPurchase,
    .DataTable__Col--canSell,
    .DataTable__Col--canConsume {
        text-align: center;
    }
}

.InventoryDetailsMeasuresUpdate__Title {
    display: flex;
    align-items: center;
    gap: 0 16px;
}
