.PropertyEditNumber {
    .MuiInputBase-input {
        min-width: 200px;
    }
}

.PropertyEditNumber__ButtonInputContainer {
    display: flex;

    .FieldValidator__ErrorWrapper.hide {
        display: none;
    }
}

.PropertyEditNumber--withButtons {
    .MuiButton-root.MyButton {
        min-width: 0;
        padding: 0 12px;
        max-height: 40px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: none;

        &:first-child {
            border-radius: 5px 0 0 5px;
            border-right: 0;
        }
        &:last-child {
            border-radius: 0 5px 5px 0;
            border-left: 0;
        }
    }

    .MyTextInput {
        width: 104px;

        .MuiInputBase-root {
            border-radius: 0;
            border-left: 0;
            border-right: 0;
            min-width: 0;
        }
        .MuiInputBase-input {
            // min-width: 112px;
            min-width: 0;
            text-align: center;

            appearance: textfield;
            &::-webkit-inner-spin-button {
                display: none;
            }
        }
    }
}
