@import '../../../../variables.scss';

.WorkItemFieldDisplay {
    // prevent text breaking on hyphens and commas
    line-break: auto;
    box-decoration-break: clone;

    // WorkItemFieldAppearance
    &.HIGHLIGHT_BLUE {
        background-color: rgba(0, 238, 255, 0.5);
        padding-inline: 6px;
        margin-inline: -6px;
        color: rgba(0, 0, 0, 0.7);
    }
    &.HIGHLIGHT_GREEN {
        background-color: rgba(4, 255, 0, 0.45);
        padding-inline: 6px;
        margin-inline: -6px;
        color: rgba(0, 0, 0, 0.7);
    }
    &.HIGHLIGHT_YELLOW {
        background-color: #f7f489;
        padding-inline: 6px;
        margin-inline: -6px;
        color: #615d05;
    }
    &.HIGHLIGHT_PINK {
        background-color: rgba(255, 0, 123, 0.45);
        padding-inline: 6px;
        margin-inline: -6px;
        color: rgba(0, 0, 0, 0.7);
    }
}
