@import '../../../../variables.scss';

.ScheduleWorkTable {
    display: table;
    border-spacing: 0;
    padding: 0 12px;

    &--Empty {
        margin-top: 0;

        .ScheduleWorkTable__Head {
            opacity: 0;
            .ScheduleWorkTable__HeaderRow {
                height: 0;
            }
        }
    }

    &--Collapsed {
        box-sizing: border-box;
        opacity: 0;

        // prevent the table from overflowing the collapsed date block
        // cant use overflow: hidden because it breaks the sticky behaviour
        position: relative;
        top: -48px;
        z-index: -1;
    }

    .smooth-dnd-container.vertical {
        width: fit-content;
        min-width: 100%;

        & > .smooth-dnd-draggable-wrapper {
            overflow: visible;
            display: flex;
        }
    }
}

.ScheduleWorkTable__EmptyMessage__Wrapper {
    display: flex;
    // Prevent the dnd handler from displacing the message when dragging over
    transform: none !important;
}

.ScheduleWorkTable__EmptyMessage {
    color: $color-grey-base;
    padding-left: 54px;
    padding-bottom: 12px;
    left: calc(-1 * var(--schedulePagePadding));
    top: 0;
    position: sticky;
    text-align: left;
}

.ScheduleWorkTable__HeaderRow {
    display: flex;
    font-size: 12px;
    color: $color-grey-dark;
    background-color: white;
    width: fit-content;
}

.ScheduleWorkTable__HeaderRow__ColsWrapper {
    display: flex;
    align-items: flex-end;
    gap: 0 16px;
}

.ScheduleWorkTable__HeaderRow__Cell {
    flex: 0 0 auto;
    background: white;
    padding: 4px 0;

    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
}

.ScheduleWorkTable__HeaderRow__Cell--DragHandle {
    background: white;
    position: sticky;
    left: calc(-1 * var(--schedulePagePadding));
    width: 68px;
    background: linear-gradient(90deg, white 90%, transparent 100%);
}

.ScheduleWorkTable__HeaderRow__Cell--Menu {
    background: white;
    position: sticky;
    right: calc(-1 * var(--schedulePagePadding));
    width: 44px;
    background: linear-gradient(90deg, transparent 0%, white 20%);
}

.ScheduleWorkTable__Row {
    display: flex;
    align-items: center;
    background-color: white;
    margin-bottom: -1px;
    white-space: nowrap;
    transition: opacity 0.3s ease;
    width: 100%;

    &:not(:first-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .ScheduleWorkTable.collapsed & {
        opacity: 0;
    }

    &--highlight {
        background-color: $color-warning-light;
    }
}

.ScheduleWorkTable__Row__ClickableWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;
    white-space: nowrap;
    cursor: pointer;
    gap: 16px;
}

.ScheduleWorkTable__Row__Cell {
    overflow: hidden;
    white-space: nowrap;

    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
}

.ScheduleWorkTable__Row__Cell__Value {
    overflow: hidden;

    &.ScheduleWorkTable__Row__Cell__Value--ellipsis {
        text-overflow: ellipsis;
    }
}

.ScheduleWorkTable__Row__Cell--Handle {
    font-size: 20px;
    position: sticky;
    left: 0;
    height: 100%;
    background: linear-gradient(90deg, white 90%, transparent 100%);
    min-width: 44px;
}

.ScheduleWorkTable__Row__DragHandle {
    height: 32px;
    color: $color-secondary-base;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px 0 12px;
    min-width: 68px;
    cursor: grab;

    .ScheduleMain__DateBlock--Loading & {
        cursor: default;
    }
}

.ScheduleWorkTable__Row__Cell--Menu {
    font-size: 24px;
    height: 32px;
    color: $color-secondary-base;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    right: 0;
    background: linear-gradient(90deg, transparent 0%, white 20%);

    .MyMenuButton {
        border: 1px solid transparent;
        border-bottom: 0;
        &.menu-open {
            background-color: white;
            border-color: $color-grey-base;
        }
        .MyButton {
            &.Mui-disabled {
                background-color: transparent;
            }
        }
    }

    .smooth-dnd-ghost & {
        right: 0;
    }
}

.ScheduleWorkTable__SlackDays {
    &.negative {
        color: $color-danger-base;
        font-weight: $font-weight-bold;
    }
}
