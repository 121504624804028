@import '../../../../variables.scss';

.ShipmentStatusBadge {
    &.OPEN {
        background-color: white;
        color: $color-primary-base;
        border-color: $color-primary-light;
    }
    &.LOCKED {
        background-color: $color-primary-light;
        border-color: $color-primary-light;
    }
    &.COMPLETED {
        background-color: $color-accent-lighter;
        border-color: $color-accent-lighter;
        color: $color-accent-dark;
    }
}
