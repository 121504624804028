@import '../../variables.scss';

.MySkeleton {
    display: flex;
    flex-direction: column;

    .MySkeleton__Line {
        transform: scale(1, 0.8);
        border-radius: 3px;
        background-color: $color-grey-lightest;
    }
}
