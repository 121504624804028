@import '../../../../variables.scss';

// Work Items
.SalesItemDrawerWorkTab {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.SalesItemDrawerWorkTab__Loading {
    display: flex;
    justify-content: center;
    padding: 24px 0;
}

.SalesItemDrawerWorkTab__Empty {
    text-align: center;
    color: $color-grey-base;
    padding: 12px;
}

.SalesItemDrawerWorkTab__SectionEmpty {
    text-align: center;
    color: $color-grey-light;
    padding: 12px;
}

.SalesItemDrawerWorkTab__Header__Counts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.SalesItemDrawerWorkTab__WorkOrder__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: $color-secondary-lighter;
}

.SalesItemDrawerWorkTab__WorkOrder__Header__Field {
    display: flex;
    gap: 12px;

    .Link {
        display: flex;
        align-items: center;
        gap: 6px;
    }
}

.SalesItemDrawerWorkTab__WorkOrder__ItemsTable {
    .DataTable__DataCell:first-child {
        padding-left: 12px;
    }
    .DataTable__DataCell:last-child {
        padding-right: 12px;
    }
}

.SalesItemDrawerWorkTab__StatusCell {
    display: flex;
    gap: 12px;
    align-items: center;

    .WorkStepCountsProgress {
        flex: 1;
    }
}
