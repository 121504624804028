.InventoryMeasureEditFields__Value {
    gap: 8px 4px;
    .PropertyDisplay__Label {
        vertical-align: baseline;
    }
    .MyNumberInput .MuiInputBase-input {
        max-width: 120px;
        min-width: 120px;
    }
}
