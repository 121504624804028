@import '../../variables.scss';

.MyScrollableModal__Frame {
    padding: 0;
    display: flex;
}

.MyScrollableModal__Body {
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: $modal-full-height;
}

.MyScrollableModal__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 24px;
    border-bottom: 1px solid $color-grey-lighter;
}

.MyScrollableModal__Content {
    padding: 32px 32px;
    overflow-y: auto;
    flex: 1;
}

.MyScrollableModal__Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px 32px;
    border-top: 1px solid $color-grey-lighter;
}

.MyScrollableModal--with-tabs {
    .MyScrollableModal__Header {
        border: 0;
        padding-bottom: 12px;
        min-height: 84px;
    }
    .MyScrollableModal__Content {
        padding-top: 0;
    }
    .MyTabs__Tabs {
        margin-bottom: 32px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;
    }
    .MyTabs__TabPanel {
        padding: 0 12px;
    }
}
