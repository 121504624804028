@import '../../../../variables.scss';

.WorkstationStepStatusArea {
    margin: 0 -32px;
}

.WorkstationStepStatusArea__Warning {
    background-color: $color-warning-light;
    color: $color-warning-darker;
    padding: 12px 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ul,
    p {
        margin: 0;
    }

    &.WorkstationStepStatusArea__Warning--SalesOrderStatus {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;

        .badge {
            font-size: 12px;
        }
    }

    & + .WorkstationStepStatusArea__Warning {
        border-top: 1px solid $color-warning-base;
    }
}

.WorkstationStepStatusArea__StatusBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;

    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.READY {
        border-color: $color-secondary-light;
        background-color: $color-secondary-lightest;
    }
    &.COMINGUP {
        border-color: $color-secondary-light;
    }
    &.INPROGRESS {
        background-color: $color-primary-lightest;
        border-color: $color-primary-lighter;
    }
    &.ONHOLD {
        background-color: $color-danger-lightest;
        border-color: $color-danger-lighter;
    }
    &.COMPLETED {
        background-color: $color-accent-lightest;
        border-color: $color-accent-light;
    }
    // &.SKIPPED {
    //    use default styling
    // }
}

.WorkstationStepStatusArea__StatusBar__Main {
    flex: 1;
}

.WorkstationStepStatusArea__StatusBar__Buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
