@import '../../../../variables.scss';

.SalesItemDrawer {
    left: unset;
}

.SalesItemDrawer__Drawer {
    width: 540px;
    max-width: 100vw;
    max-width: 100dvw;

    .MyDrawer__Drawer__Body {
        padding-top: 0;
    }

    transition: margin-right 0.2s ease;
    &.SalesItemDrawer__Drawer--WorkItemOpen {
        margin-right: 200px;
    }
}

.SalesItemDrawer__Header__BackButton {
    padding: 4px;
    height: auto;
    min-width: 0;

    .MyButton__Inner__IconRight {
        font-size: 28px;
    }
}

.SalesItemDrawer__PageHeader {
    height: 40px;

    .PageHeader__TitleContainer__Title {
        font-size: 20px;
    }
}

.SalesItemDrawer__CancelledMessage {
    margin-top: 16px;
    margin-bottom: 8px;
}

.SalesItemDrawer__DetailsTab {
    padding-bottom: 60px;
}

.SalesItemDrawer__SectionHeader {
    margin-top: 24px;
    font-size: 14px;
}

.SalesItemDrawer__DetailsTab__DocumentsList {
    list-style: none;
    margin: 0;
    padding: 0;

    li:before {
        content: '⏵';
        margin-right: 8px;
    }
}

.SalesItemDrawer__SectionEmpty {
    color: $color-grey-light;
}

.SalesItemDrawer__PricingDisplay {
    border-spacing: 0;
    margin-top: 24px;
    width: 100%;

    th,
    td {
        font-weight: $font-weight-normal;
        text-align: left;
        padding-bottom: 8px;
        padding-right: 32px;
    }
    th {
        color: $color-grey-base;
    }

    thead {
        th:first-child {
            font-weight: $font-weight-bold;
            color: black;
            padding-bottom: 16px;
        }
    }
    tbody {
        // th {
        //     padding-right: 24px;
        // }
        td {
            font-weight: $font-weight-normal;
            text-align: left;
        }
    }
}

.SalesItemDrawer__HistoryTable {
    .DataTable__DataCell:first-child {
        padding-left: 12px;
    }
    .DataTable__DataCell:last-child {
        padding-right: 12px;
    }
}
