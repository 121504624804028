@import '../../../../variables.scss';

.WorkItemStepUiStatusBadge--pill {
    &.READY {
        background-color: white;
        color: $color-primary-base;
        border-color: $color-primary-light;
    }
    &.COMINGUP {
        background-color: white;
        color: $color-secondary-base;
        border-color: $color-secondary-light;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
        border-color: $color-primary-light;
    }
    &.ONHOLD {
        background-color: $color-danger-light;
        border-color: $color-danger-light;
        color: white;
    }
    &.COMPLETED {
        background-color: $color-accent-light;
        border-color: $color-accent-light;
        color: $color-accent-darker;
    }
    &.SKIPPED {
        background-color: $color-accent-lighter;
        border-color: $color-accent-lighter;
        color: $color-accent-dark;
    }
}

.WorkItemStepUiStatusBadge--title,
.WorkItemStepUiStatusBadge--text {
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.WorkItemStepUiStatusBadge--title {
    .WorkItemStepUiStatusBadge__Label {
        text-align: left;
        font-size: 16px;
        font-weight: $font-weight-bold;
    }
}

.WorkItemStepUiStatusBadge--icon {
    font-size: 16px;
    text-align: center;
    margin: 0;
    align-items: center;
}

.WorkItemStepUiStatusBadge__Icon {
    border-radius: 100%;
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 15px;
        height: 15px;
    }

    &.COMINGUP {
        background-color: transparent;
        border: 5px solid $color-secondary-light;
    }
    &.READY {
        background-color: transparent;
        border: 5px solid $color-primary-light;
    }
    &.ONHOLD {
        background-color: $color-danger-light;
        color: white;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
    }
    &.SKIPPED {
        background-color: $color-accent-light;
    }
    &.COMPLETED {
        background-color: $color-accent-light;
        color: $color-accent-dark;
    }
}
