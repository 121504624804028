@import 'variables.scss';

.LayoutBody {
    width: max-content;
    min-width: 100%;

    .LayoutHeader + & {
        margin-top: 16px;
    }
}
