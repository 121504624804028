@import '../../../../variables.scss';

.AddMovementDialog {
    padding: 32px;
    min-width: 0;
    width: 600px;
    max-width: 100%;
}

.AddMovementDialog__Fields {
    display: flex;
    flex-direction: column;
    gap: 16px;

    background-color: $color-secondary-lighter;
    padding: 24px;
    border-radius: 5px;

    .MySelectInput,
    .PropertyEditSelect__InputWrapper__Input {
        width: 100%;
    }
}

.AddMovementDialog__FieldRow {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.AddMovementDialog__QuantityDisplay {
    .positive {
        color: $color-checked-green;
    }
    .negative {
        color: $color-error;
    }
}

.AddMovementDialog__ButtonRow {
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: flex-end;
    margin-top: 16px;

    .MyButton {
        min-width: 120px;
    }
}

.AddMovementDialog__AdjustmentForm__NewQuantity,
.AddMovementDialog__WriteOffForm__Quantity {
    .FieldValidator__ErrorWrapper {
        overflow: visible;
        white-space: nowrap;
        padding-top: 4px;
    }
}
