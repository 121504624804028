@import '../../../../variables.scss';

.InventoryReceiptStatusBadge {
    &.DRAFT {
        border-color: $color-checked-green;
        color: $color-checked-green;
        background-color: white;
    }

    &.PROCESSED {
        background-color: $color-checked-green;
        border-color: $color-checked-green;
    }
}
