@import '../../../../variables.scss';

.WorkStepCountsProgress {
    display: flex;
    gap: 8px;
    align-items: center;
}

.WorkStepCountsProgress__Bar {
    flex: 1;
    height: 8px;
    background-color: $color-grey-lighter;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    overflow: hidden;
}

.WorkStepCountsProgress__Bar__Progress {
    &.CANCELLED {
        background-color: $color-danger-base;
    }
    &.SKIPPED,
    &.COMPLETED {
        background-color: $color-accent-base;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
    }
    &.ONHOLD {
        background-color: $color-danger-light;
    }
    &.UNSTARTED {
        background-color: $color-grey-lighter;
    }
}

.WorkStepCountsProgress__Percent {
    font-size: 12px;
}

.WorkStepCountsProgress__Tooltip {
    padding: 8px;
}

.WorkStepCountsProgress__Tooltip__Header {
    margin-bottom: 8px;
}

.WorkStepCountsProgress__Tooltip__Statuses {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.WorkStepCountsProgress__TooltipStepStatus {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
}

.WorkStepCountsProgress__TooltipStepStatus__Color {
    border-radius: 100%;
    width: 16px;
    height: 16px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);

    &.UNSTARTED {
        background-color: $color-grey-lighter;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
    }
    &.ONHOLD {
        background-color: $color-danger-light;
    }
    &.SKIPPED,
    &.SKIPPED_LOCKED,
    &.COMPLETED,
    &.COMPLETED_LOCKED {
        background-color: $color-accent-light;
    }
}

.WorkStepCountsProgress__TooltipStepStatus__Label {
    flex: 1;
    padding-right: 12px;
}

.WorkStepCountsProgress__TooltipStepStatus__Value {
    font-size: 14px;
    font-weight: $font-weight-semi-bold;
}
