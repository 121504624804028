@import '../../../../variables.scss';

.StocktakeStatusBadge {
    &.PREPARATION {
        background-color: white;
        color: $color-primary-base;
        border-color: $color-primary-light;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
        border-color: $color-primary-light;
    }
    &.COMPLETE {
        background-color: $color-accent-lighter;
        border-color: $color-accent-lighter;
        color: $color-accent-dark;
    }
}
