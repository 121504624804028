@import '../../../../variables.scss';

.InventoryReceiptAddItemModal__Frame {
    width: 720px;
}

.InventoryReceiptAddItemModal {
    .PageHeader__TitleContainer__Title {
        font-size: 14px;
    }
}

.InventoryReceiptAddItemModal__Header {
    flex-wrap: wrap;

    .MyEditModal__Header__PageHeader {
        margin-bottom: 8px;
    }
}

.InventoryReceiptAddItemModal__InventoryReceiptInventoryInputWrapper {
    flex: 1 0 100%;
}

.InventoryReceiptAddItemModal__ItemTable__EmptyRow {
    transition: all 200ms ease;
    opacity: 1;

    &.enter {
        opacity: 0;
        height: 0;
    }
    &.enter-active,
    &.enter-done {
        height: 40px;
        transition-delay: 200ms;
        opacity: 1;
    }
}

.InventoryReceiptAddItemModal__ItemRow {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 176px 32px;
    align-items: center;
    padding: 4px 8px;
    transition: all 200ms ease;
    transform-origin: top center;
    height: fit-content;
    max-height: 200px;

    &.enter {
        opacity: 0;
        transform: translateX(-100%);
    }
    &.enter-active {
        opacity: 1;
        transform: translateX(0);
    }
    &.exit {
        opacity: 1;
        height: 48px;
    }
    &.exit-active {
        opacity: 0;
        max-height: 0;
        height: 0;
        padding: 0px 8px;
    }

    @keyframes InventoryReceiptItemFlash {
        0% {
            background-color: unset;
        }
        50% {
            background-color: $color-warning-light;
        }
        100% {
            background-color: unset;
        }
    }
    &.flash {
        animation: InventoryReceiptItemFlash 400ms;
    }
}

.InventoryReceiptAddItemModal__InventoryLabel {
    margin-block: auto;
}

.InventoryReceiptAddItemModal__PropertyEdit__Quantity {
    .PropertyEdit__Label {
        display: none;
    }
    .PropertyEditNumber__Input {
        width: 78px;
    }
}

.InventoryReceiptAddItemModal__PropertyEdit__Remove {
    padding: 0;
    width: 32px;
    min-width: 32px;
}

.InventoryReceiptAddItemModal__Footer {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
}

.InventoryReceiptAddItemModal__Footer__Cancel,
.InventoryReceiptAddItemModal__Footer__AddItems {
    flex: 0 0 140px;
}
