@import '../../../../variables.scss';

.PackagesTable__ShipmentDisplay {
    .top {
        display: flex;
        align-items: center;
        gap: 6px;
    }
    .tuid {
        color: rgba(0, 0, 0, 0.5);
        font-size: 11px;
    }
    .name {
        font-weight: $font-weight-semi-bold;
    }
}
