@import '../../../../variables.scss';

.WorkItemStepHistoryModal__Frame {
    width: 800px;
}

.WorkItemStepHistoryModal__Header__ItemDescription {
    color: $color-grey-dark;
    font-size: 15px;
}

.WorkItemStepHistoryModal__Status {
    display: flex;
    align-items: center;
    gap: 6px;
}

.WorkItemStepHistoryModal__Status__Workstation {
    color: $color-grey-base;
}

.WorkItemStepHistoryModal__Workstations__Title {
    display: flex;
    color: $color-grey-base;
    align-items: center;
    gap: 6px;
    margin-bottom: 4px;
}

.WorkItemStepHistoryModal__Workstations__Loading {
    padding-block: 12px;
}

.WorkItemStepHistoryModal__Workstations__Empty {
    color: $color-warning-dark;
}

.WorkItemStepHistoryModal__Workstations__List {
    margin: 0;
    padding: 0;
}

.WorkItemStepHistoryModal__WorkstationLinkItem {
    display: flex;
    align-items: center;
    gap: 6px;
}

.WorkItemStepHistoryModal__WorkstationLinkItem__CurrentIcon {
    color: $color-warning-base;
}

.WorkItemStepHistoryModal__HistoryTitle {
    margin-top: 24px;
    margin-bottom: 12px;
}
