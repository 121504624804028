@import '../../variables';

.PageHeader {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 24px;
    justify-content: space-between;
}

.PageHeader__TitleContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
}

.PageHeader__TitleContainer__Title {
    font-size: 24px;
    font-weight: $font-weight-semi-bold;
    margin: 0;
}

.PageHeader__TitleContainer__Title__Separator {
    font-style: normal;
    color: $color-grey-light;
    font-weight: $font-weight-normal;
    margin: 0 10px;
}

.PageHeader__TitleContainer__Title__Context {
    font-style: normal;
    color: $color-grey-light;
    font-weight: $font-weight-normal;
}

.PageHeader__TitleContainer__Subtitle {
    font-size: 14px;
    color: $color-grey-base;
    margin: 0;
}

.PageHeader__ContentContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
}
