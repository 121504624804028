@import '../../../../variables.scss';

.SalesOrderDetailModal__Frame {
    width: 1200px;
}

.SalesOrderDetailModal__Header {
    margin-bottom: 0;
}

.SalesOrderDetailModal__PageHeader__Actions {
    display: flex;
    gap: 6px;
}

.SalesOrderDetailModal__WarningMessage {
    margin-top: 12px;
}

.SalesOrderDetailModal__Details {
    display: flex;
    flex-direction: row-reverse;
    flex: 1 1;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 24px;
}

.SalesOrderDetailModal__OrginalFreight {
    color: $color-grey-light;
}

.SalesOrderDetailModal__Sidebar {
    max-width: 38.2%; // golden ratio ϕ
    padding-left: 24px;
    min-width: 180px;
}

.SalesOrderDetailModal__Sidebar__GroupOrdersCount {
    font-size: 13px;
    color: $color-grey-base;
}

.SalesOrderDetailModal__Sidebar__LinksTitle {
    font-size: 14px;
    margin-bottom: 0;
}

.SalesOrderDetailModal__Sidebar__Links {
    list-style: none;
    margin: 0;
    padding: 0;

    li:before {
        content: '⏵';
        margin-right: 8px;
    }
}

.SalesOrderDetailModal__Tabs {
    margin-top: 12px;
}

.SalesOrderDetailModal__NotesHtml {
    & *:first-child {
        margin-top: 0;
    }
    & *:last-child {
        margin-bottom: 0;
    }
}

.SalesOrderDetailModal__StatusBadges {
    display: flex;
    gap: 6px;
    align-items: center;
    flex-wrap: wrap;
}

// Bulk actions tooltbar
.SalesOrderDetailModal__ItemsTab__Toolbar {
    overflow: hidden;
    max-height: 40px;
    margin-bottom: 8px;
    transition: max-height 0.3s ease, margin-bottom 0.3s ease;
    display: flex;
    gap: 6px;
    align-items: center;

    &.collapsed {
        max-height: 0;
        margin-bottom: 0;
        opacity: 0;
    }
}

.SalesOrderDetailModal__ItemsTab__TableContainer {
    overflow: auto;
}

.SalesOrderDetailModal__ItemsTable {
    .DataTable__HeaderCell--Select,
    .DataTable__DataCell--Select {
        position: sticky;
        left: 0;
        z-index: 1;
    }

    .DataTable__DataCell--progress {
        padding-block: 0;
        position: relative;
        width: 150px;
    }
}

.SalesOrderDetailModal__ProgressCell {
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    left: 8px;
    align-content: center;
}

.SalesOrderDetailModal__LatestActivityCell {
    font-size: 12px;
}
