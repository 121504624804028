@import '../../../../variables.scss';

.CustomersTable__FilterBar {
    .MyAutocompleteInput {
        min-width: 200px;
    }
    .MySelectInput {
        min-width: 180px;
    }
}

.CustomersTable__DataTable {
    .DataTable__DataCell--accessType {
        font-size: 16px;
    }
}

.CustomersTable__PaymentMethod {
    &.DEFAULT {
        opacity: 0.3;
    }
}

.CustomersTable__Status {
    &.NONE {
        opacity: 0.3;
    }
}
