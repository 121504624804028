@import '../../../../variables.scss';

.WorkItemsProgress {
    display: flex;
    gap: 8px;
    align-items: center;
}

.WorkItemsProgress__Bar {
    flex: 1;
    height: 8px;
    background-color: $color-grey-lighter;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    overflow: hidden;
}

.WorkItemsProgress__Bar__Progress {
    &.CANCELLED {
        background-color: $color-danger-base;
    }
    &.COMPLETED {
        background-color: $color-accent-base;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
    }
    &.ONHOLD {
        background-color: $color-danger-light;
    }
    &.UNSTARTED {
        background-color: $color-grey-lighter;
    }
}

.WorkItemsProgress__Percent {
    font-size: 12px;
}

.WorkItemsProgress__Tooltip {
    padding: 8px;
}

.WorkItemsProgress__Tooltip__Header {
    margin-bottom: 8px;
}

.WorkItemsProgress__Tooltip__Statuses {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.WorkItemsProgress__TooltipWorkItemStatus {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.WorkItemsProgress__TooltipWorkItemStatus__Badge {
    flex: 1;
    padding-right: 12px;
    .WorkItemStatusBadge__Icon {
        box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    }
}

.WorkItemsProgress__TooltipWorkItemStatus__Label {
    flex: 1;
    padding-right: 12px;
}

.WorkItemsProgress__TooltipWorkItemStatus__Value {
    font-size: 14px;
    font-weight: $font-weight-semi-bold;
}
