@import '../../../../variables.scss';

.DocumentsTable__NameCell {
    display: flex;
    align-items: center;
    gap: 12px;

    .Link {
        color: $color-link-dark;
    }
}

.DocumentsTable__ArchiveButton {
    padding: 0 4px;
    min-width: 0;
    height: auto;

    .MyButton__Inner__IconLeft {
        font-size: 18px;
    }
}
