@import '../../../../variables.scss';

.WorkItemDrawer {
    left: unset;

    .MyDrawer__Drawer__Header {
        margin-bottom: 24px;
    }
}
.WorkItemDrawer__Drawer {
    width: 540px;

    .MyDrawer__Drawer__Body {
        padding-top: 0;
    }
}

.WorkItemDrawer__Header__BackButton {
    padding: 4px;
    height: auto;
    min-width: 0;

    .MyButton__Inner__IconRight {
        font-size: 28px;
    }
}

.WorkItemDrawer__PageHeader {
    .PageHeader__TitleContainer__Title {
        font-size: 20px;
    }
}
.WorkItemDrawer__CancelledMessage {
    margin-bottom: 24px;
}

.WorkItemDrawer__WorkItemStepList {
    margin-top: 24px;
}

.WorkItemDrawer__SectionHeader {
    margin-top: 24px;
    font-size: 14px;
}

.WorkItemDrawer__SectionEmpty {
    color: $color-grey-light;
}

.WorkItemDrawer__Status {
    display: flex;
    gap: 12px;
    align-items: center;
}
