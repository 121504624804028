@import '../../variables.scss';

.MyAutocompleteInput {
    &.MyAutocompleteInput--multiple {
        width: 100%;
        .MuiInputBase-root {
            height: auto;
            width: 100%;
        }
    }

    &.MyAutocompleteInput--full-width {
        width: 100%;
    }
}

.MyAutocompleteInput__TextField {
    .MuiInputBase-input {
        min-width: 120px;
    }
}

// option displayed in the main input as tags
.MyAutocompleteInput__TagChip {
    background-color: $color-secondary-base;
    color: white;
    font-size: 13px;
    gap: 6px;
    padding: 0 6px;
    margin: 2px;

    .MuiChip-deleteIcon {
        color: white;
        opacity: 0.3;
        font-size: 16px;
    }

    &.inactive {
        background-color: $color-secondary-light;
        color: $color-secondary-base;

        .MuiChip-deleteIcon {
            color: $color-secondary-base;
        }
    }
}

// option displayed in the dropdown
.MyAutocompleteInput__Option.inactive {
    color: $color-grey-light;
}
