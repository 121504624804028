@import '../../../../variables.scss';

.InventoryReceiptTable__FilterBar {
    .DataTableCriteria__Field--Select,
    .DataTableCriteria__Field--Date {
        .MySelectInput {
            min-width: 150px;
        }
    }
}

.InventoryReceiptTable__FilterBar__Actions {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.InventoryReceiptTable__StockLevelSelectFilter {
    min-width: 200px;
}
