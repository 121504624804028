@import '../../variables.scss';

.ErrorContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.ErrorContent--align-center {
    text-align: center;
    align-items: center;
    justify-content: center;

    .ErrorContent__TopWrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .ErrorContent__Icon {
        font-size: 32px;
    }
}

.ErrorContent--align-left {
    text-align: left;

    .ErrorContent__TopWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    .ErrorContent__Icon {
        font-size: 24px;
    }
}

.ErrorContent__Title {
    margin: 0;
    font-size: 16px;
}

.ErrorContent__Message {
    margin: 0;
}
