@import '../../../../variables.scss';

.ForgotPasswordForm__Form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.ForgotPasswordForm__FormError {
    background-color: $color-danger-lightest;
    color: $color-danger-dark;
    border: 1px solid $color-danger-light;
    font-size: 13px;
    border-radius: 5px;
    padding: 8px 16px;
    margin-bottom: 8px;
}
