@import '../../../../variables.scss';

.InventoryReceiptItemLocationModal__Frame {
    min-width: 720px;
}

.InventoryReceiptItemLocationModal__Header {
    padding-bottom: 0;
}

.InventoryReceiptItemLocationModal__MessagePanel {
    margin-bottom: 16px;
}

.InventoryReceiptItemLocationModal__Location {
    display: flex;
    gap: 8px;
    align-items: flex-end;
    overflow: hidden;
    transition: all 200ms ease;
    transform-origin: top center;
    height: fit-content;
    max-height: 200px;

    &.unassigned {
        padding-right: 40px;
    }

    &.exit {
        opacity: 1;
        height: 48px;
    }
    &.exit-active {
        opacity: 0;
        max-height: 0;
        height: 0;
    }

    @keyframes InventoryReceiptItemLocationModal__Location-flash {
        0% {
            background-color: unset;
        }
        50% {
            background-color: $color-warning-light;
        }
        100% {
            background-color: unset;
        }
    }
    &.flash {
        animation: InventoryReceiptItemLocationModal__Location-flash 400ms;
    }
}

.InventoryReceiptItemLocationModal__Quantity {
    display: flex;
    justify-content: space-between;
}

.InventoryReceiptItemLocationModal__Location__Remove {
    padding: 0;
    width: 32px;
    min-width: 32px;
}

.InventoryReceiptItemLocationModal__Total_Row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
    gap: 16px;
}

.InventoryReceiptItemLocationModal__Footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
