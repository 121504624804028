@import 'variables';

.MyButton.AnimatedRefreshButton {
    padding: 0 8px;
    min-width: 0;

    &.refreshing {
        .MyButton__Inner__IconRight {
            animation: criteria-refresh-icon-spin 1s infinite;
            color: $color-grey-light;
        }
    }
}
