@import '../../../../variables.scss';

.WorkItemStepList {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.WorkItemStepList__Progress {
    margin-bottom: 8px;
}

.WorkItemStepList__Progress__Counts {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 8px;
}

.WorkItemStepList__Table {
    .DataTable__DataCell {
        padding: 4px 12px;
    }
    .chevron {
        color: $color-secondary-base;
    }
}

.WorkItemStepList__TransitionCell__Date {
    font-size: 12px;
}

.WorkItemStepList__TransitionCell__Workstation {
    font-size: 11px;
    color: $color-secondary-base;
}

.WorkItemStepList__SkippedTitle {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 0;
}
