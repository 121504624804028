@import '../../../../variables.scss';

.InventoryLocationDetailsMovements__FilterBar {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.InventoryLocationDetailsMovements__FilterBar__AddButton {
    min-width: 160px;
    max-height: 40px;
}

.InventoryLocationDetailsMovements__DataTable {
    .DataTable__Col--notes {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
    }

    .DataTable__Col--quantity {
        text-align: center;

        .positive {
            color: $color-checked-green;
            font-weight: $font-weight-bold;
        }
        .negative {
            color: $color-danger-base;
            font-weight: $font-weight-bold;
        }
    }
}
