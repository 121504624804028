@import '../../../../variables.scss';

.WorkOrderDetailModal__Frame {
    width: 700px;
}

.WorkOrderDetailModal__SectionHeader {
    font-size: 16px;
    margin-top: 24px;
}

.WorkOrderDetailModal__WorkstationGroupWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    height: 32px;

    .FieldValidator__ErrorWrapper.hide {
        display: none;
    }
}

.WorkOrderDetailModal__WorkstationGroup__Select {
    height: 32px;
}

.WorkOrderDetailModal__WorkItems__Counts {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 8px;
    margin-top: 24px;
}

.WorkOrderDetailModal__WorkItems__Progress {
    margin-bottom: 24px;
}

.WorkOrderDetailModal__WorkItemsTable__Status {
    display: flex;
    justify-content: flex-start;
    gap: 6px;

    .cancelledBadge {
        background-color: $color-warning-light;
        padding: 2px 12px;
        border-radius: 24px;
        font-size: 12px;
        color: $color-warning-dark;
        white-space: nowrap;
        display: flex;
        align-items: center;
        height: 24px;
        border: 1px solid $color-warning-base;
    }
}
