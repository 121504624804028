@import '../../../../variables.scss';

.InventoryDetailsModal__Frame {
    width: 1000px;
}

.InventoryDetailsModal__AttributesPanel {
    padding: 12px 0 0 32px;
    border-left: 8px solid $color-secondary-light;
    margin-bottom: 24px;
}

.InventoryDetailsModal__TabLoadingPanel {
    width: 100%;
    padding: 64px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.InventoryDetailsModal__TabEmptyPanel {
    text-align: center;
    padding: 64px 0;
    animation: emptyStateFadeIn 0.5s ease;
    position: relative;
    h2 {
        font-weight: $font-weight-bold;
        font-size: 16px;
        margin: 0 0 8px 0;
    }
    p {
        font-size: 13px;
        color: $color-grey-base;
        margin: 0;
    }
}

.InventoryDetailsModal__PropertyContainer {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-between;
}

.InventoryDetailsModal__PropertyContainer__Main {
    flex: 2 0 auto;
}

.InventoryDetailsModal__UnitsWrapper {
    max-width: 38.2%; // golden ratio ϕ
    padding-left: 24px;
    min-width: 180px;
}

.InventoryDetailsModal__FixedWidthField {
    min-width: 208px;
}
