@import '../../../../variables.scss';

.WorkItemStatusBadge--pill {
    color: white;
    background-color: $color-secondary-base;

    &.UNSTARTED {
        background-color: white;
        border-color: $color-secondary-light;
        color: $color-secondary-base;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
        border-color: $color-primary-light;
    }
    &.ONHOLD {
        background-color: $color-danger-light;
        border-color: $color-danger-light;
        color: white;
    }
    &.COMPLETED {
        background-color: $color-accent-light;
        border-color: $color-accent-light;
        color: $color-accent-darker;
    }
}

.WorkItemStatusBadge--title,
.WorkItemStatusBadge--text {
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;

    .WorkItemStatusBadge__Label {
        text-align: left;
    }
}

.WorkItemStatusBadge--icon {
    font-size: 16px;
    text-align: center;
    margin: 0;
    align-items: center;
}

.WorkItemStatusBadge__Icon {
    border-radius: 100%;
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 15px;
        height: 15px;
    }

    &.UNSTARTED {
        background-color: transparent;
        border: 5px solid $color-secondary-light;
    }
    &.ONHOLD {
        background-color: $color-danger-light;
        color: white;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
    }
    &.COMPLETED {
        background-color: $color-accent-light;
        color: $color-accent-darker;
    }
}
