@import '../../../../variables.scss';

.SalesItemDrawerHistoryTab__SectionEmpty {
    text-align: center;
    color: $color-grey-base;
    padding: 12px;
}

.SalesItemDrawerHistoryTab__Loading {
    display: flex;
    justify-content: center;
    padding: 24px 0;
}
